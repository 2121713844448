import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "assets/theme";
import { BrowserRouter } from "react-router-dom";
import Rotas from "rotas";


function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Rotas />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
